import React from "react";
import { useNavigate } from "react-router-dom";

export const EachDocument = ({ eachDocument, size, openInNewTab, draft }) => {
  const navigate = useNavigate();

  // ! CHANGE DOCUMENT ID

  const openDocument = () => {
    if (draft) {
      navigate(`/document/${eachDocument.id}/draft`);
    } else if (openInNewTab) {
      window.open(`/document/${eachDocument.id}`, "_blank");
    } else {
      navigate(`/document/${eachDocument.id}`);
    }
  };

  return (
    <div
      className={`eachDocument  ${size === "small" && "smallDocumentPreview"}`}
      onClick={() => openDocument()}
    >
      <div className="eachDocumentPreview">
        <div className="eachDocumentImage">
          <p className="eachDocumentImageName">
            {eachDocument ? eachDocument.name : ""}
          </p>

          <>
            <div className="eachDocumentImageSkeletonContainer">
              <div className="eachDocumentImageSkeleton"></div>
              <div className="eachDocumentImageSkeleton"></div>
              <div className="eachDocumentImageSkeleton"></div>
              <div className="eachDocumentImageSkeleton"></div>
            </div>

            <div className="eachDocumentImageSkeletonContainer">
              <div className="eachDocumentImageSkeleton"></div>
              <div className="eachDocumentImageSkeleton"></div>
              <div className="eachDocumentImageSkeleton"></div>
              <div className="eachDocumentImageSkeleton"></div>
            </div>
          </>

          {/* <p className="eachDocumentImageName">{eachDocument.name}</p> */}
        </div>
      </div>
      <div className="eachDocumentInfo">
        <h1 className="eachDocumentName">
          {eachDocument ? eachDocument.name : ""}
        </h1>
        {/* {eachDocument.reason && (
          <Tooltip label="Why this?" text={eachDocument.reason} />
        )} */}
      </div>
    </div>
  );
};
