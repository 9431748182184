import React from "react";
import { Oval } from "react-loader-spinner";

export const DocumentViewerLoader = ({ text = "Loading PDF" }) => {
  return (
    <div className="documentViewerLoader">
      <Oval
        height={20}
        width={20}
        color="#1252f3"
        wrapperStyle={{}}
        visible={true}
        ariaLabel="oval-loading"
        secondaryColor="#1252f320"
        strokeWidth={5}
        strokeWidthSecondary={5}
      />

      <p className="documentViewerLoaderText">{text}</p>
    </div>
  );
};
