import React, { useEffect, useState } from "react";
import { BsStars } from "react-icons/bs";
import { FiChevronRight } from "react-icons/fi";
import { HiOutlineArrowRight } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Loader } from "rsuite";
import { createDocumentDraftAPI, validateAnswerAPI } from "../../api/apiCalls";
import { updateChat } from "../../redux/actions/chatAction";
import { updateStepper } from "../../redux/actions/stepperActions";
import { IoChatbubblesSharp } from "react-icons/io5";
// import "rsuite/dist/rsuite-no-reset.min.css";

export const NextStepButton = () => {
  const { steps, processDetails, stepTitles, warnings, mainStep, currentStep } =
    useSelector((state) => state.stepper);
  const { creatingProcess, context, location } = useSelector(
    (state) => state.document
  );

  const [searchParams] = useSearchParams();

  const navigate = useNavigate();
  const params = useParams();

  const dispatch = useDispatch();

  const [documentCreating, setDocumentCreating] = useState(false);

  const createDocument = () => {
    dispatch(
      updateStepper({
        warnings: [],
      })
    );

    setDocumentCreating(true);

    const data = {
      document: params.id,
      context: context,
      jurisdiction: `${location.state}, ${location.country}`,
      data: stepTitles.map((step, index) => {
        return {
          title: step.title,
          question: steps[index].section.description,
          answer: steps[index].inputs.value,
        };
      }),
    };

    // // //"Create document Data: ", data);

    // console.log("SENDING DATA: ", otherDocumentData);

    const response = createDocumentDraftAPI({
      ...data,
      document: params.id,
    });
    response.then((apiResponse) => {
      if (apiResponse.data.status === "PROCESS_COMPLETED") {
        setDocumentCreating(false);
        // // //"Create document api response: ", apiResponse.data);

        navigate(`/document/${apiResponse.data.draft_id}/draft`);
      }
    });
  };

  const changeCurrentStep = (value) => {
    // if (currentStep === 1) {
    //   let newStepsArray = [...steps];

    //   const stringObjectOfInputs = Object.entries(newStepsArray[0].inputs)
    //     .map(([name, value]) => {
    //       //
    //       const nameToChange = Object.entries(newNamesForBasicInfo)
    //         .map(([inputName, newInputName]) => {
    //           if (inputName === name) {
    //             return {
    //               inputName: inputName,
    //               newInputName: newInputName,
    //             };
    //           }
    //         })
    //         .filter((value) => value !== undefined)[0];

    //       if (nameToChange) {
    //         return `${nameToChange.newInputName}: ${value}`;
    //       } else if (name === "partners") {
    //         let text = ``;
    //         value.map((eachPartner, index) => {
    //           text += `${index + 1}. \n ${eachPartner.partnerName}: ${
    //             eachPartner.partnerAddress
    //           }\n\n`;
    //         });

    //         return `Partners Involved: ${text}`;
    //       } else return `${toSentenceCase(name)}: ${value}`;
    //     })
    //     .join("\n");

    //   newStepsArray[0] = {
    //     ...newStepsArray[0],
    //     inputs: {
    //       ...newStepsArray[0].inputs,
    //       value: stringObjectOfInputs,
    //     },
    //   };

    //   dispatch(
    //     updateStepper({
    //       steps: newStepsArray,
    //     })
    //   );
    // }

    switch (value) {
      case "next":
        if (
          !steps[currentStep - 1].inputs ||
          steps[currentStep - 1].inputs.value.length === 0
        ) {
          let newStepsArray = [...steps];
          newStepsArray[currentStep - 1] = {
            ...newStepsArray[currentStep - 1],
            error: "Add a valid answer",
          };

          dispatch(
            updateStepper({
              steps: newStepsArray,
            })
          );
        } else {
          let newArray = [...steps];
          newArray[currentStep - 1] = {
            ...newArray[currentStep - 1],
            warning: null,
          };
          dispatch(
            updateStepper({
              steps: newArray,
            })
          );

          return validateAnswer();
        }

        break;
      case "previous":
        if (currentStep <= 1) {
          navigate(`/document/${params.id}`);
        } else
          navigate(`/document/${params.id}/create?step=${currentStep - 1}`);
        break;
      default:
        break;
    }
  };

  const validateAnswer = async (onSuccess) => {
    if (onSuccess) {
      onSuccess();
    } else {
      dispatch(
        updateStepper({
          currentStep: currentStep + 1,
        })
      );
      // navigate(`/document/${params.id}/create?step=${currentStep + 1}`);
    }
  };

  // const validateAnswer = async (onSuccess) => {
  //   // // console.log({
  //   //   processID: params.id,
  //   //   step: currentStep,
  //   //   question: steps[currentStep - 1].title,
  //   //   example: steps[currentStep - 1].example,
  //   //   suggestions: steps[currentStep - 1].answers,
  //   //   answer: steps[currentStep - 1].inputs?.value ?? "",
  //   // });

  //   // creating new warnings array and adding the warnings to that array and checking with the steps
  //   let localStep = currentStep - 1;

  //   let warningsArray = [...warnings];
  //   let isWarningStepAdded = warnings.filter(
  //     (eachWarning) => eachWarning.step === localStep
  //   )[0];

  //   if (warnings.length === 0 || !isWarningStepAdded) {
  //     // console.log("PUSHING NEW OBJECT");

  //     warningsArray.push({
  //       step: localStep,
  //       loading: true,
  //     });
  //   } else {
  //     // console.log("UPDATING OBJECT");

  //     if (isWarningStepAdded) {
  //       warningsArray = warnings.map((eachWarning) => {
  //         if (eachWarning.step === localStep) {
  //           return {
  //             ...eachWarning,
  //             loading: true,
  //           };
  //         } else {
  //           return eachWarning;
  //         }
  //       });
  //     }
  //   }

  //   dispatch(
  //     updateStepper({
  //       warnings: [...warningsArray],
  //     })
  //   );

  //   // let newArray = [...steps];
  //   // newArray[localStep - 1] = {
  //   //   ...newArray[localStep - 1],
  //   //   warning: {
  //   //     loading: true,
  //   //   },
  //   // };

  //   // dispatch(
  //   //   updateStepper({
  //   //     steps: newArray,
  //   //   })
  //   // );

  //   try {
  //     const response = await validateAnswerAPI({
  //       // processID: params.id,
  //       question: steps[localStep].title,
  //       example: steps[localStep].example,
  //       suggestions: steps[localStep].answers,
  //       answer: steps[localStep].inputs?.value ?? "",
  //       // stepTitle: stepTitles[localStep].title,
  //       // stepDescription: stepTitles[localStep].description,
  //       step: localStep,
  //       documentID: params.id,
  //     });

  //     if (response.data) {
  //       let newWarningsArray = [...warningsArray];
  //       let isWarningStepAdded = warnings.filter(
  //         (eachWarning) => eachWarning.step === localStep
  //       )[0];

  //       // console.log("NEW WARNINGS: ", newWarningsArray);

  //       // console.log("LOADING FALSE");

  //       newWarningsArray = warningsArray.map((eachWarning) => {
  //         // console.log(eachWarning.step, localStep);

  //         if (eachWarning.step === localStep) {
  //           return {
  //             ...eachWarning,
  //             loading: false,
  //             // replace message
  //             message: response.data.Advice,
  //             accuracy: response.data.Accuracy,
  //           };
  //         } else {
  //           return eachWarning;
  //         }
  //       });

  //       // console.log(newWarningsArray);

  //       dispatch(
  //         updateStepper({
  //           warnings: newWarningsArray,
  //         })
  //       );

  //       // dispatch(
  //       //   updateStepper({
  //       //     steps: newArray,
  //       //   })
  //       // );

  //       // console.log(response.data);

  //       if (response.data.Accuracy >= 7) {
  //       //
  //       }
  //     }
  //   } catch (error) {
  //     let newWarningsArray = [...warningsArray];
  //     let isWarningStepAdded = warnings.filter(
  //       (eachWarning) => eachWarning.step === localStep
  //     )[0];

  //     // console.log("NEW WARNINGS: ", newWarningsArray);

  //     // console.log("LOADING FALSE");

  //     newWarningsArray = warningsArray.map((eachWarning) => {
  //       // console.log(eachWarning.step, localStep);

  //       if (eachWarning.step === localStep) {
  //         return {
  //           ...eachWarning,
  //           loading: false,
  //         };
  //       } else {
  //         return eachWarning;
  //       }
  //     });

  //     // console.log(newWarningsArray);

  //     dispatch(
  //       updateStepper({
  //         warnings: newWarningsArray,
  //       })
  //     );
  //   }
  // };

  // // const newArray = [...steps];
  useEffect(() => {
    // console.log(steps);
    document.addEventListener("pressNextButton", function (event) {
      alert("event");
      changeCurrentStep("next");
    });
  }, []);

  const openChat = () => {
    // if (steps[currentStep - 1]) {
    //   dispatch(
    //     updateChat({
    //       chatContext: {
    //         title: stepTitles[currentStep - 1].title,
    //         question: steps[currentStep - 1].title,
    //         documentInfo: {
    //           id: params.id,
    //           title: stepTitles[currentStep - 1].title,
    //           question: steps[currentStep - 1].title,
    //           step: currentStep - 1,
    //         },
    //       },
    //     })
    //   );
    // }

    navigate(`/document/${params.id}/create/chat`);
  };

  // // //steps);
  // // //currentStep);

  // setDocumentCreating(false);

  // const warningsForAnswers =
  //   currentStep === steps.length &&
  //   steps.filter(
  //     (eachStep) =>
  //       eachStep.warning &&
  //       eachStep.warning.message &&
  //       eachStep.warning.accuracy < 7
  //   ).length !== 0;

  const currentStepWarning = () => {
    return warnings.filter(
      (eachWarning) => eachWarning.step + 1 === currentStep
    )[0];
  };

  return (
    <div
      className={`createDocumentButtonContainer ${
        steps && "createDocumentButtonStepper"
      }`}
    >
      <button
        className={`needHelpButton ${
          !steps[currentStep - 1] && "disabledButton"
        }`}
        disabled={!steps[currentStep - 1]}
        onClick={() => {
          openChat();
        }}
      >
        <IoChatbubblesSharp className="needHelpIcon" />
        <p className="needHelpText">Get help from Airstrip AI</p>

        <p className="needHelpTextExample">ex: Help me find the best option</p>
      </button>
      {/* {JSON.stringify(steps[currentStep])} */}

      {steps[currentStep - 1] && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            // flexDirection: "column",
          }}
        >
          <p className="keyboardShortcutText">Cmd/Shift + Enter</p>

          <button
            className={`createDocumentButton ${
              (documentCreating ||
                (creatingProcess && !steps[currentStep]) ||
                currentStepWarning()?.loading) &&
              "createDocumentLoading"
            }`}
            // disabled={warningsForAnswers}
            onClick={() => changeCurrentStep("next")}
          >
            {documentCreating && (
              <Loader
                className="createDocumentLoadingSpinner"
                backdrop={false}
              />
            )}

            {/* {currentStep}
            {steps.length} */}

            {documentCreating ? (
              <p className="createDocumentButtonText">Creating your Document</p>
            ) : (
              <p className="createDocumentButtonText">
                {/* Next Step: {stepTitles[currentStep].title} */}
                Next Question
              </p>
            )}
            {/* <HiOutlineArrowRight className="createDocumentButtonIcon" /> */}
            <FiChevronRight className="createDocumentButtonIcon" />
          </button>
          {/* {currentStep === steps.length && (
            <p className="warningAnswersText">
              <AiOutlineExclamation
                className="contextInputErrorIcon"
                style={{
                  width: "12px",
                  height: "12px",
                }}
              />
              You have incorrect answers, correct them to get a accurate
              document.
            </p>
          )} */}
        </div>
      )}
    </div>
  );
};
