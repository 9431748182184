import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NextStepButton } from "./NextStepButton";
import { Suggestions } from "./Suggestions";
import { updateStepper } from "../../redux/actions/stepperActions";
import { useSearchParams } from "react-router-dom";
import { DocumentDetailLoader } from "../Loaders/DocumentDetailLoader";

import { animated, easings, useSpring } from "@react-spring/web";
import { AiOutlineExclamation } from "react-icons/ai";
import { PiWarningCircleFill } from "react-icons/pi";
import { RiLightbulbFlashFill } from "react-icons/ri";
import { Oval } from "react-loader-spinner";
import { CgAdd } from "react-icons/cg";
import { IoAdd } from "react-icons/io5";
import { IoIosAddCircle } from "react-icons/io";

export const GeneralInputStep = () => {
  const { steps, documentDetails, stepTitles, warnings, currentStep } =
    useSelector((state) => state.stepper);
  const { creatingProcess, location } = useSelector((state) => state.document);
  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();

  const [showSuggestions, setShowSuggestions] = useState(false);

  const [openedSuggestions, setOpenedSuggestions] = useState([]);

  const inputRef = useRef();

  const isSuggestionOpened = (id) =>
    openedSuggestions.filter((suggestion) => suggestion === id)[0];

  const toggleSuggestionOpen = (id) => {
    if (isSuggestionOpened(id)) {
      const newOpenedSuggestionsArray = openedSuggestions.filter(
        (suggestion) => suggestion !== id
      );
      setOpenedSuggestions(newOpenedSuggestionsArray);
    } else {
      setOpenedSuggestions([...openedSuggestions, id]);
    }
  };

  const [selectedSuggestions, setSelectedSuggestions] = useState([]);

  const updateInput = (value) => {
    let newStepsArray = [...steps];

    newStepsArray[currentStep - 1] = {
      ...newStepsArray[currentStep - 1],
      error: null,
      inputs: {
        value: value,
      },
    };

    dispatch(
      updateStepper({
        steps: newStepsArray,
      })
    );
  };

  // // // //"console", steps[currentStep - 1]);

  useEffect(() => {
    // // //steps[currentStep - 1]);
    // // //creatingProcess);

    // // //stepTitles);

    setSelectedSuggestions([]);

    // setSelectedSuggestion({
    //   index: null,
    //   title: null,
    //   description: null,
    // });
    setOpenedSuggestions([]);
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
      if (inputRef) inputRef?.current?.focus();
    }, 200);
  }, [currentStep]);

  const [modalProps, setmodalProps] = useSpring(() => ({
    from: {
      opacity: 0,
      marginTop: 20,
    },
    to: {
      opacity: 1,
      marginTop: 0,
    },
  }));

  const getExampleSuggestion = (message) => {
    const regex = /"([^"]*)"/;
    const matches = message?.match(regex);
    const extractedText = matches ? matches[1] : null;

    return extractedText;
  };

  const currentStepWarning = () => {
    return warnings.filter(
      (eachWarning) => eachWarning.step + 1 === currentStep
    )[0];
  };

  useEffect(() => {
    //
  }, []);

  return (
    <>
      {/* here{steps.length} */}
      {/* {JSON.stringify(location)} */}
      {!steps ||
      creatingProcess ||
      !steps[currentStep - 1] ||
      !steps[currentStep - 1].title ? (
        <div
          className={`createDocumentGeneralInput createDocumentRight`}
          id="createDocumentGeneralInput"
        >
          {/* <DocumentDetailLoader /> */}
          <div className="skeletonLoader">
            <div className="skeletonLoaderElement skeletonHeader"></div>
            <div className="skeletonParagraphGroup">
              <div className="skeletonLoaderElement skeletonParagraph"></div>
              <div className="skeletonLoaderElement skeletonParagraph"></div>
              <div className="skeletonLoaderElement skeletonParagraph"></div>
            </div>

            {/* <div className="skeletonLoaderElement skeletonDivider"></div>

      <div className="skeletonLoaderElement skeletonParagraph skeletonTask"></div>
      <div className="skeletonLoaderElement skeletonParagraph skeletonTask"></div>
      <div className="skeletonLoaderElement skeletonParagraph skeletonTask"></div> */}

            {/* <div className="skeletonLoaderElement skeletonDivider"></div> */}

            <div className="skeletonLoaderElement skeletonButton"></div>
          </div>
        </div>
      ) : (
        <animated.div
          className={`createDocumentGeneralInput createDocumentRight`}
          style={modalProps}
          id="createDocumentGeneralInput"
        >
          {/* <p className="generalInputHeaderLabel">{documentDetails.title}</p> */}
          <p className="generalInputLogoName">Airstrip AI</p>
          <p className="generalInputHeaderText documentDetailHeaderText">
            <img src="/logowhiteblue.png" className="generalInputLogoIcon" />

            {steps[currentStep - 1].title}
          </p>
          {/* <p className="generalInputHeaderDescription documentDetailHeaderDescription">
            ex: {steps[currentStep - 1].example}
          </p> */}
          <textarea
            ref={inputRef}
            type="text"
            className="generalInput"
            id="generalInput"
            placeholder="Add your decision here..."
            // placeholder={steps[currentStep - 1].answers[0].description}
            autoFocus
            value={steps[currentStep - 1].inputs?.value ?? ""}
            onChange={(e) => updateInput(e.target.value)}
          ></textarea>

          {steps[currentStep - 1].error && (
            <p className="contextInputError stepperErrorText">
              <AiOutlineExclamation className="contextInputErrorIcon" />
              <p className="stepperErrorTextLabel">
                {steps[currentStep - 1].error}
              </p>
            </p>
          )}

          {/* {JSON.stringify(steps[currentStep - 1)} */}

          {/* {JSON.stringify(warnings)} */}

          {currentStepWarning() && currentStepWarning()?.loading && (
            <div className="answerMistakeSuggestionLoading">
              <div className="answerMistakeSuggestionLoadingContent">
                <p className="mistakeLoadingContentHeader">
                  Checking your answer
                </p>
                <p className="mistakeLoadingContentDescription">
                  This helps us make sure you the document does not have any
                  inaccuracies and to get an accurate document that fits your
                  needs
                </p>
              </div>

              <Oval
                height={20}
                width={20}
                color="#1252f3"
                wrapperStyle={{}}
                visible={true}
                wrapperClass="answerMistakeLoadingIcon"
                ariaLabel="oval-loading"
                secondaryColor="#1252f320"
                strokeWidth={5}
                strokeWidthSecondary={5}
              />
            </div>
          )}

          {currentStepWarning() && !currentStepWarning()?.loading && (
            <div
              className={`answerMistakeSuggestion ${
                currentStepWarning()?.accuracy <= 7 &&
                "importantMistakeSuggestion"
              }`}
            >
              <div className="answerMistakeSuggestionLeft">
                {currentStepWarning()?.accuracy <= 7 ? (
                  <PiWarningCircleFill className="answerMistakeSuggestionIcon" />
                ) : (
                  <RiLightbulbFlashFill
                    className="answerMistakeSuggestionIcon"
                    style={{ outline: "none" }}
                  />
                )}

                <p className="answerMistakeSuggestionTextHeader">
                  {currentStepWarning()?.accuracy <= 7
                    ? "Suggestion to improve your answer"
                    : "Ways to improve your answer more (optional)"}
                </p>
              </div>
              <div className="answerMistakeSuggestionRight">
                <p className="answerMistakeSuggestionText">
                  {/* {JSON.stringify(currentStepWarning()?)} */}
                  {currentStepWarning()?.message}
                  {/* The answer does not specify any actual duties or obligations for
                TaskBuddy and Stripe. To improve accuracy, clearly list the key
                responsibilities of each party based on the suggestions, using
                precise language. <br />
                <br /> For example, state TaskBuddy will provide customer
                service, ensure data security, etc. And Stripe will process
                payments, implement fraud protection, etc. Providing these
                specifics will capture the roles and meet the intent of the
                question. Let me know if you need any clarification! */}
                </p>

                {getExampleSuggestion(currentStepWarning()?.message) && (
                  <div
                    className="answerMistakeSuggestionButton"
                    onClick={() =>
                      updateInput(
                        getExampleSuggestion(currentStepWarning()?.message)
                      )
                    }
                  >
                    <IoIosAddCircle className="answerMistakeSuggestionButtonIcon" />
                    <p>Add this answer</p>
                  </div>
                )}
              </div>
            </div>
          )}
          {/* 
          <Suggestions
            setShowSuggestions={setShowSuggestions}
            showSuggestions={showSuggestions}
            selectedSuggestions={selectedSuggestions}
            setSelectedSuggestions={setSelectedSuggestions}
            openedSuggestions={openedSuggestions}
            setOpenedSuggestions={setOpenedSuggestions}
            updateInput={updateInput}
            input={steps[currentStep - 1].inputs?.value ?? ""}
          /> */}
        </animated.div>
      )}
    </>
  );
};
