import React, { useEffect, useState } from "react";
import { Navbar } from "../components/Navbar";
import { useDispatch, useSelector } from "react-redux";
import { EachDocument } from "../components/EachDocument";
import { documents } from "./App";
import { getMyDraftsAPI } from "../api/apiCalls";
import { IoAdd } from "react-icons/io5";
import { Link } from "react-router-dom";
import { HiDocument } from "react-icons/hi2";
import { updateUser } from "../redux/actions/userAction";

export const MyDocuments = () => {
  const [drafts, setDrafts] = useState(null);

  const dispatch = useDispatch();

  const getMyDrafts = async () => {
    const response = await getMyDraftsAPI();

    setDrafts(response.data);

    // // //"drafts: ", response.data);
  };

  useEffect(() => {
    getMyDrafts();
  }, []);

  return (
    <div className="myDocumentsContainer">
      <Navbar />

      <div className="rightContainer">
        {/* <div className="myDocumentsHeader">
          <p className="myDocumentsHeaderText">Your Saved Documents</p>
          {drafts && (
            <p className="myDocumentsLengthCount">{drafts.length} Documents</p>
          )}
        </div> */}

        <div className="rightContainerHeader">
          <h1 className="rightContainerHeaderText">Your Saved Documents</h1>
          <p className="rightContainerHeaderDescription">
            {drafts && (
              <p className="myDocumentsLengthCount">
                {drafts.length} Documents
              </p>
            )}
          </p>
        </div>

        {drafts ? (
          drafts.length === 0 ? (
            <div className="emptyMyDocuments">
              <HiDocument className="emptyDocumentsIcon" />
              <p className="emptyMyDocumentsHeader">
                Choose and create your document now
              </p>
              <p className="emptyMyDocumentsDescription">
                Select the document that you need, then start creating it for
                your use-cases.
              </p>
              <button
                onClick={() =>
                  dispatch(updateUser({ showDocumentsModal: true }))
                }
                className="emptyMyDocumentsButton"
              >
                Create Document ->
              </button>
            </div>
          ) : (
            <div className="myDocumentsList">
              {drafts.map((eachDocument) => (
                <EachDocument
                  eachDocument={{
                    ...eachDocument,
                    name: eachDocument.name,
                    id: eachDocument._id,
                  }}
                  draft={true}
                />
              ))}
            </div>
          )
        ) : (
          "Loading"
        )}
      </div>
    </div>
  );
};
