import React from "react";
import * as Dialog from "@radix-ui/react-dialog";
import "../../dialog.css";
import { MdClose } from "react-icons/md";

const RadixModal = ({
  triggerComponent,
  children,
  title,
  description,
  buttonText,
  onClick,
  openModal,
  setOpenModal,
  className,
}) => (
  <div className={`DialogContainer`}>
    <Dialog.Root open={openModal} onOpenChange={setOpenModal}>
      {/* <Dialog.Trigger asChild>{triggerComponent()}</Dialog.Trigger> */}
      <Dialog.Portal>
        <Dialog.Overlay className="DialogOverlay" />
        <Dialog.Content className={`DialogContent ${className}`}>
          <div className="DialogHeader">
            <Dialog.Title className="DialogTitle">{title}</Dialog.Title>
            <Dialog.Description className="DialogDescription">
              {description}
            </Dialog.Description>
          </div>

          <div className="DialogContentContainer">{children}</div>

          <Dialog.Close asChild className="ButtonContainers">
            <button onClick={() => onClick()} className="ButtonMain">
              {buttonText}
            </button>
          </Dialog.Close>

          <Dialog.Close asChild>
            <button className="IconButton" aria-label="Close">
              <MdClose />
            </button>
          </Dialog.Close>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  </div>
);

export default RadixModal;
